import Mail from 'images/Contacts/Mail.png';
import Telegram from 'images/Contacts/Telegram.png';
import LinkedIn from 'images/Contacts/LinkedIn.png';
import Skype from 'images/Contacts/Skype.png';

import Serhii from 'images/Contacts/Serhii.png';
import Artem from 'images/Contacts/Artem.png';
import Eva from 'images/Contacts/Eva.png';
import Nikita from 'images/Contacts/Nikita.png';
import Kiril from 'images/Contacts/Kiril.png';
import Anton from 'images/Contacts/Anton.png';

import {
  ContactsImg,
  ItemName,
  ItemPosition,
  SocialList,
  SocialListItemImg,
} from '../Contacts.styled';

import { ContactsMobList, MobListItem } from './ContactsMobile.styled';

export const ContactsMobile = () => {
  return (
    <>
      <ContactsMobList>
        <MobListItem>
          <ContactsImg src={Artem} alt="employee" />
          <ItemName>Artem</ItemName>
          <ItemPosition>Co-Founder&CEO</ItemPosition>

          <SocialList>
            <li>
              <a target="_blank" rel="noreferrer" href="//t.me/artem_click2dep">
                <SocialListItemImg src={Telegram} alt="Telegram" />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="mailto:artem.click2dep@gmail.com"
              >
                <SocialListItemImg src={Mail} alt="Mail" />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="//www.linkedin.com/in/artem-click2dep-456240276/"
              >
                <SocialListItemImg src={LinkedIn} alt="LinkedIn" />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="//join.skype.com/invite/CVBtw6m6bU2z"
              >
                <SocialListItemImg
                  style={{ width: '28px' }}
                  src={Skype}
                  alt="Skype"
                />
              </a>
            </li>
          </SocialList>
        </MobListItem>
        <MobListItem>
          <ContactsImg src={Eva} alt="employee" />
          <ItemName>Eva</ItemName>
          <ItemPosition>Co-Founder&CEO</ItemPosition>
          <SocialList>
            <li>
              <a target="_blank" rel="noreferrer" href="//t.me/eva_click2dep">
                <SocialListItemImg src={Telegram} alt="Telegram" />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="mailto:click2dep@gmail.com"
              >
                <SocialListItemImg src={Mail} alt="Mail" />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="//www.linkedin.com/in/eva-bazan-7905b9248"
              >
                <SocialListItemImg src={LinkedIn} alt="LinkedIn" />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="//join.skype.com/invite/LKpoW1O76w1d"
              >
                <SocialListItemImg
                  style={{ width: '28px' }}
                  src={Skype}
                  alt="Skype"
                />
              </a>
            </li>
          </SocialList>
        </MobListItem>
        <MobListItem>
          <ContactsImg src={Serhii} alt="employee" />
          <ItemName>Serhii</ItemName>
          <ItemPosition>CBDO</ItemPosition>
          <SocialList>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="//t.me/serhii_click2dep"
              >
                <SocialListItemImg src={Telegram} alt="Telegram" />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="mailto:serhiy.click2dep@gmail.com"
              >
                <SocialListItemImg src={Mail} alt="Mail" />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="//www.linkedin.com/in/serhii-tsyhanenko-62b56b2b1"
              >
                <SocialListItemImg src={LinkedIn} alt="LinkedIn" />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="//join.skype.com/invite/JqsqBiwnOhBC"
              >
                <SocialListItemImg
                  style={{ width: '28px' }}
                  src={Skype}
                  alt="Skype"
                />
              </a>
            </li>
          </SocialList>
        </MobListItem>
        <MobListItem>
          <ContactsImg src={Nikita} alt="employee" />
          <ItemName>Nikita</ItemName>
          <ItemPosition>Affiliate Manager</ItemPosition>
          <SocialList>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="//t.me/click2depp_nikita"
              >
                <SocialListItemImg src={Telegram} alt="Telegram" />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="mailto:nikita.click2dep@gmail.com"
              >
                <SocialListItemImg src={Mail} alt="Mail" />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="//www.linkedin.com/in/nikita-mosenz-2bb6482b3/"
              >
                <SocialListItemImg src={LinkedIn} alt="LinkedIn" />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="//join.skype.com/invite/IdR6HBKEMrYb"
              >
                <SocialListItemImg
                  style={{ width: '28px' }}
                  src={Skype}
                  alt="Skype"
                />
              </a>
            </li>
          </SocialList>
        </MobListItem>
        <MobListItem>
          <ContactsImg src={Anton} alt="employee" />
          <ItemName>Anton</ItemName>
          <ItemPosition>Affiliate Manager</ItemPosition>

          <SocialList>
            <li>
              <a target="_blank" rel="noreferrer" href="//t.me/antonclick2dep">
                <SocialListItemImg src={Telegram} alt="Telegram" />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="mailto:anton.click2dep@gmail.com"
              >
                <SocialListItemImg src={Mail} alt="Mail" />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="//www.linkedin.com/in/anton-shmakov-72a099322/"
              >
                <SocialListItemImg src={LinkedIn} alt="LinkedIn" />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="//join.skype.com/invite/LJNoTuvngI1c"
              >
                <SocialListItemImg
                  style={{ width: '28px' }}
                  src={Skype}
                  alt="Skype"
                />
              </a>
            </li>
          </SocialList>
        </MobListItem>
        <MobListItem>
          <ContactsImg src={Kiril} alt="employee" />
          <ItemName>Kiril</ItemName>
          <ItemPosition>Affiliate Manager</ItemPosition>
          <SocialList>
            <li>
              <a target="_blank" rel="noreferrer" href="//t.me/kiril_click2dep">
                <SocialListItemImg src={Telegram} alt="Telegram" />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="mailto:kiril.click2dep@gmail.com"
              >
                <SocialListItemImg src={Mail} alt="Mail" />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="//www.linkedin.com/in/kiril-click2dep-122a31299/"
              >
                <SocialListItemImg src={LinkedIn} alt="LinkedIn" />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="//join.skype.com/invite/Ms9vkkl4Zdrk"
              >
                <SocialListItemImg
                  style={{ width: '28px' }}
                  src={Skype}
                  alt="Skype"
                />
              </a>
            </li>
          </SocialList>
        </MobListItem>
      </ContactsMobList>
    </>
  );
};
